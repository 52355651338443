import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import useMediaQuery from "../useMediaQuery"; // Import custom hook
import Loader from "./Loader";

const Container = styled.div`
  position: relative;
  width: 100%; /* Ensure it takes full width */
  overflow: hidden;
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgb(149, 69, 53, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px;
  z-index: 1;
  &:hover {
    background-color: rgb(149, 69, 53);
  }
`;

const LeftArrow = styled(ArrowButton)`
  left: 0;
`;

const RightArrow = styled(ArrowButton)`
  right: 0;
`;

const CardContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 100%; /* Ensure container takes full width */
  // justify-content: space-between; /* Distribute cards evenly */
  padding: 0 20px; /* Optional: Add padding for spacing */
 

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 10px;
  flex: 1; /* Allow the card to grow and shrink */
  min-width: ${({ cardWidth }) => cardWidth}px;
  max-width: ${({ cardWidth }) => cardWidth}px; /* Limit the max width */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
   background-color:white;
`;

const PropertyImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const CardContent = styled.div`
  padding: 15px;
  font-size: 1rem;

  h2 {
    font-size: 1.5rem;
    margin: 10px 0;
  }

  p {
    margin: 5px 0;
    font-size: 1rem;
    strong {
      font-weight: bold;
    }
  }

  @media (max-width: 1024px) {
    font-size: 0.9rem;
    padding: 10px;

    h2 {
      font-size: 1.3rem;
    }

    p {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 8px;

    h2 {
      font-size: 1.2rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
`;

const StyledParagraph = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  line-height: 1.5;
  `

export const CenteredText = styled.p`
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
  font-size: 1.2rem;
  color: #666;
  margin-left: 630px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Newest_Listing = () => {
  const { types } = useParams();
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const cardContainerRef = useRef(null);

  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");
  const isDesktop = useMediaQuery("(min-width: 1025px)");

  const cardWidth = isMobile ? 250 : isTablet ? 300 : 350;

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/properties`);
        if (!response.ok) {
          throw new Error("Failed to fetch properties");
        }
        const data = await response.json();
        setProperties(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  const filteredProperties = properties
    .filter((property) => property.types === types && property.status === "approved")
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .slice(0, 10);

  const handleScroll = (direction) => {
    const container = cardContainerRef.current;
    const scrollAmount = 300;
    if (container) {
      if (direction === "left") {
        container.scrollLeft -= scrollAmount;
      } else {
        container.scrollLeft += scrollAmount;
      }
    }
  };

  const handleCardClick = (propertyId) => {
    navigate(`/view-property5/${propertyId}`);
  };

  const updateArrows = () => {
    const container = cardContainerRef.current;
    if (container) {
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(container.scrollLeft < maxScrollLeft);
    }
  };

  useEffect(() => {
    const container = cardContainerRef.current;
    if (container) {
      updateArrows();
      container.addEventListener("scroll", updateArrows);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", updateArrows);
      }
    };
  }, [properties]);

  if (loading) {
    return <div><Loader /></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Container>
      <h1 style={{ textAlign: "center" }}>Newest Listing for {types === "Sell" ? "Buy" : types}</h1>
      {isDesktop && showLeftArrow && (
        <LeftArrow onClick={() => handleScroll("left")}>
          <FaArrowLeft size={30} />
        </LeftArrow>
      )}
      {isDesktop && showRightArrow && (
        <RightArrow onClick={() => handleScroll("right")}>
          <FaArrowRight size={30} />
        </RightArrow>
      )}
      <CardContainer ref={cardContainerRef}>
        {filteredProperties.length > 0 ? (
          filteredProperties.map((property) => (
            <Card
              key={property._id}
              onClick={() => handleCardClick(property._id)}
              cardWidth={cardWidth}
            >
              {property.files && property.files[0] ? (
                <PropertyImage
                  src={property.files[0].startsWith("http") ? property.files[0] : `http://localhost:3007/${property.files[0]}`}
                  alt={property.propertyName}
                />
              ) : (
                <PropertyImage src="default_image_url" alt="No image available" />
              )}
              <CardContent>
                <h2>{property.propertyName}</h2>
                <StyledParagraph><strong>Property Type :</strong> {property.propertyType}</StyledParagraph>
                <StyledParagraph><strong>Budget:</strong> ₹ {property.budget} {property.budgetMethod}</StyledParagraph>
                <StyledParagraph><strong>Location:</strong> {property.propertyAddress}, {property.city}, {property.state}</StyledParagraph>
                <StyledParagraph><strong>City:</strong> {property.city}</StyledParagraph>
              </CardContent>
            </Card>
          ))
        ) : (
      
          <CenteredText>No approved properties found.</CenteredText>
          
        )}
      </CardContainer>
    </Container>
  );
};

export default Newest_Listing;
