import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../Loader';
// Styled Components (unchanged)
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.light};
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
export const ListingSection = styled.div`
  flex: 1;
  padding: 20px 40px;
  background-color: ${({ theme }) => theme.colors.light};
  @media (max-width: 1024px) {
    padding: 20px;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const Title = styled.h2`
  margin-right: 20px;
`;
export const Dropdown = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;
export const ApprovedButton = styled.button`
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 20px;
  &:hover {
    background-color: #0056B3;
  }
`;
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
  width: 100%;
`;
export const Card = styled.div`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;
export const Image = styled.img`
  width: 100%;
  max-width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
`;
export const Description = styled.p`
  font-size: 14px;
  color: #666;
  margin: 8px 0;
  text-align: center;
  strong {
    color: #333;
  }
`;
export const ViewButton = styled.button`
  background-color: #007BFF;
  color: white;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  &:hover {
    background-color: #0056B3;
  }
`;
const NoPropertyMessage = styled.p`
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
  color: ${({ theme }) => theme.colors.dark}; /* Adjust color if needed */
`;

const LoadingMessage = styled.p`
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
  color: ${({ theme }) => theme.colors.dark}; /* Adjust color if needed */
`;


const PropertyListingAdmin = () => {
  const { title } = useParams();
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [types, setTypes] = useState('all');
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  // Fetch properties from the server
  const fetchProperties = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/propertyListAdmin`);
      setList(response.data);
      setFilteredList(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    fetchProperties(); // Fetch all properties initially
  }, []);

  useEffect(() => {
    // Update the filtered list based on the selected type
    if (types === 'all') {
      setFilteredList(list);
    } else {
      const filtered = list.filter(item => item.types.toLowerCase() === types.toLowerCase());
      setFilteredList(filtered);
    }
  }, [types, list]);



  return (
    <Container>
      <ListingSection>
        <Header>
          <TitleContainer>
            <Title>All Properties</Title>
            <Dropdown value={types} onChange={(e) => setTypes(e.target.value)}>
              <option value="all">All Types</option>
              <option value="sell">Sell</option>
              <option value="rent">Rent</option>
              <option value="lease">Lease</option>
            </Dropdown>
          </TitleContainer>
          {/* <ApprovedButton onClick={() => navigate('/approveProperty')}>Approved</ApprovedButton> */}
        </Header>

        {/* Show loading message when loading */}
        {loading ? (
          <LoadingMessage><Loader/></LoadingMessage>
        ) : (
          <Grid>
            {filteredList.length > 0 ? (
              filteredList.map((item, index) => (
                <Card key={index}>
                  <Image
                    src={item.files && item.files[0] ? item.files[0] : 'https://via.placeholder.com/150'}
                    alt={item.propertyName}
                  />
                  <Description><strong>Code ID: </strong>{item.code}</Description>
                  <Description><strong>Property Name: </strong>{item.propertyName}</Description>
                  <Description><strong>Property Type: </strong>{item.propertyType}</Description>
                  <Description><strong>Types: </strong>{item.types}</Description>
                  <Description><strong>Budget: </strong>{item.budget} {item.budgetMethod}</Description>
                  <ViewButton onClick={() => navigate(`/individualadmin/${item._id}`)}>View Details</ViewButton>
                </Card>
              ))
            ) : (
              <NoPropertyMessage>No properties found for the selected type.</NoPropertyMessage>
            )}
          </Grid>
        )}
      </ListingSection>
    </Container>
  );
};

export default PropertyListingAdmin;