// src/theme.js

export const theme = {
    colors: {
      light: "#E7E1D4",
      dark: "#2A2E27",
      medium: "#954535",
      extralight: "#F2F8FC",
    }
  };
  