import React from 'react';
import logo from './logo.svg';
import './App.css';
import styled from 'styled-components';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Signin from './Components/Account/Signin'; // Import the Signin component
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'; // Import Navigate
import { ThemeProvider } from 'styled-components';
import { theme } from './theme'; // Import your theme
import '@fortawesome/fontawesome-free/css/all.min.css';
import Sell from './Components/Sell';
import Contact from './Components/Contact';
import Signup from './Components/Account/Signup';
import { AuthProvider } from './AuthProvider';
// import Wishlist from './Components/Wishlist/Wishlist';
import Profile from './Components/Account/Profile';
import SellProperty from './Components/Customer_Sell/Sell_Property';
// import EditProperty from './Components/Customer_Sell/Edit_Sell_Property';
import EditProfile from './Components/Account/EditProfile';
import BackToTop from './Components/BackToTop';
import LoginError from './Components/LoginError';
import SellProperties from './Components/Account/SellProperties';
import PropertyListing from './Components/PropertyListing';
import PrivacyPolicy from './Components/FooterFiles/PrivacyPolicy';
import TermsAndConditions from './Components/FooterFiles/TermsAndCondition';
import IndividualProperty from './Components/Account/ViewProperty';
import EditProperty from './Components/Account/EditProperty';
import IndividualProperty2 from './Components/Individual';
import SimilarProduct from './Components/SimilarProduct';
import SimilarProperties from './Components/SimilarProperties';
import { WishlistProvider } from './WishlistContext';
import IndividualProperty5 from './Components/Ind2';
import Banner from './Components/HomePage/Banner';
import ImageTest from './Components/ImageTest';
import Loader from './Components/Loader';
import Newest_Listing from './Components/Newest_Listing';
import FeaturedCollections from './Components/FeaturedCollections';
import CommonPage from './Components/CommonPage';
import AboutUs from './Components/FooterFiles/AboutUs';
import Popup from './Components/PopupMessage';
import ApproveProperty from './Components/Admin/ApprovePage';
import CustomerDetails from './Components/Admin/CustomerDetails';
import AdminHome from './Components/Admin/HomePageAdmin';
import IndividualAdmin from './Components/Admin/InividualAdmin';
import PropertyListingAdmin from './Components/Admin/PropertyList';
import UploadImage from './Components/Admin/UploadImage';
import SearchRent from './Components/Search/SearchRent';
import Forgot from './Components/Account/ForgotPassword';
import ScrollToTop from './Components/ScrollUp';
import Plans from './Components/Plan';
import EditPropertyAdmin from './Components/Admin/EditPropertyAdmin';


function App() {
  return (
    <WishlistProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <div>
              <AppContainer>
                <Header />
                <MainContent>
                <ScrollToTop/>
                  <Routes>
                    <Route path="/" element={<Navigate to="/common/Sell" />} /> 
                    <Route path="/signin" element={<Signin />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/profile" element={<Profile />} />
                    {/* <Route path="/buy" element={<Buy />} /> */}
                    <Route path="/sell" element={<Sell />} />
                    {/* <Route path="/rent" element={<Rent />} />
                    <Route path="/lease" element={<Lease />} /> */}
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/imagetest" element={<ImageTest />} />
                    <Route path="/banner" element={<Banner />} />
                    {/* <Route path="/wishlist" element={<Wishlist />} /> */}
                    <Route path="/editprofile" element={<EditProfile />} />
                    <Route path="/loginerror" element={<LoginError />} />
                    <Route path="/popup" element={<Popup />} />
                    <Route path="/approveproperty" element={<ApproveProperty/>} />
                    <Route path="/customerdetails" element={<CustomerDetails/>} />
                    <Route path="/adminhome" element={<AdminHome/>} />
                    {/* <Route path="/individualadmin" element={<IndividualAdmin/>} /> */}
                    <Route path="/individualadmin/:propertyId" element={<IndividualAdmin />} />
                    <Route path="/propertylistingadmin" element={<PropertyListingAdmin/>} />
                    <Route path="/uploadimage" element={<UploadImage/>} />
                    <Route path="/loader" element={<Loader />} />
                    <Route path="/sellproperties" element={<SellProperties />} />
                    <Route path="/common/:types" element={<CommonPage />} />
                    <Route path="/propertylisting" element={<PropertyListing />} />
                    <Route path="/newestlisting/:types" element={<Newest_Listing />} />
                    <Route path="/featuredcollections/:types" element={<FeaturedCollections />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    <Route path="/view-property/:propertyId" element={<IndividualProperty />} />
                    <Route path="/view-property5/:propertyId" element={<IndividualProperty5 />} />
                    {/* <Route path="/edit-property/:propertyId" element={<EditProperty />} /> */}
                    <Route path="/editProperty/:id" element={<EditProperty />} />
                    <Route path="/termsandconditions" element={<TermsAndConditions/>} />
                    {/* <Route path="/propertylisting/:title" element={<PropertyListing />} />
                    <Route path="/propertylisting1/:title" element={<PropertyListing1 />} />
                    <Route path="/propertylisting2/:title" element={<PropertyListing2 />} /> */}
                    <Route path="/property/:propertyId" element={<IndividualProperty2 />} />
                    <Route path="/similar-product/:propertyId" element={<SimilarProduct />} />
                    <Route path="/similarproperties" element={<SimilarProperties />} />
                    <Route path="/about" element={<AboutUs/>} />
                    <Route path="/propertylistings/:propertyName/:city" element={<SearchRent/>} /> 
                    <Route path="/forgotpassword" element = {<Forgot />} />                  
                    <Route path="/plans" element = {<Plans />} />                  
                    <Route path="/editpropertyadmin" element = {<EditPropertyAdmin />} />             
                     
                     {/* <Route path="/sellproperty" element={<SellProperty />} />
                     <Route path="/editproperty" element={<EditProperty />} /> */}
                  </Routes>
                </MainContent>
                <BackToTop />
                <Footer />
              </AppContainer>
            </div>
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </WishlistProvider>
  );
}

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the container takes at least the full viewport height */
`;
const MainContent = styled.main`
  flex: 1; /* Allows the main content to grow and fill available space */
  /* Add your content styles here */
`;

export default App;
