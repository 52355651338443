import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import useMediaQuery from '../useMediaQuery';

const Container = styled.div`
  padding: 20px;
  background-color: #E7E1D4;
  position: relative; /* To position arrows */
`;

const Heading = styled.h2`
  margin-bottom: 20px;
  text-align: center;
`;

const ScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 10px 0;

  /* Hide scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
`;

const PropertyCard = styled.div`
  position: relative;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 0 10px;
  min-width: ${(props) => props.minWidth};
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

    .overlay {
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    min-width: ${(props) => props.minWidth768};
  }

  @media (max-width: 480px) {
    min-width: ${(props) => props.minWidth480};
  }
`;

const PropertyImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const PropertyDetails = styled.div`
  padding: 15px;
  background-color: #fff;
`;

const PropertyTitle = styled.h3`
  margin: 0;
  font-size: 1.5em;
  color: #333;
`;

const PropertyInfo = styled.p`
  margin: 5px 0;
  color: #666;
  font-size: 0.9em;
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.1); 
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  ${({ direction }) => (direction === "left" ? "left: 10px;" : "right: 10px;")}
`;

const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15%;
  background: rgba(89, 89, 89, 0.2);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2A2E27;
  font-size: 1.2em;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
`;

const HeartIcon = styled(FaHeart)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  color: ${({ liked }) => (liked ? "red" : "#999999")};
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const SimilarProperties = ({ propertyName, city, types, currentId }) => {
  const [similarProperties, setSimilarProperties] = useState([]);
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);

  const isTabletOrMobile = useMediaQuery('(max-width: 768px)');
  const isMobile = useMediaQuery('(max-width: 480px)');

  useEffect(() => {
    const fetchSimilarProperties = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/similar-properties`,
          {
            params: { name: propertyName, city, types, currentId },
          }
        );
        setSimilarProperties(response.data);
      } catch (error) {
        console.error("Error fetching similar properties:", error);
      }
    };

    fetchSimilarProperties();
  }, [propertyName, city, types, currentId]);

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === "left" ? -300 : 300;
      scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const toggleHeart = (propertyId) => {
    setSimilarProperties((prevProperties) =>
      prevProperties.map((property) =>
        property._id === propertyId
          ? { ...property, liked: !property.liked }
          : property
      )
    );
  };

  const getCardMinWidth = () => {
    if (isMobile) return '90%';
    if (isTabletOrMobile) return '45%';
    return '300px';
  };

  return (
    similarProperties.length > 0 && (
      <Container>
        <Heading>Similar Properties</Heading>
        <ArrowButton direction="left" onClick={() => scroll("left")}>
          &#9664;
        </ArrowButton>
        <ScrollContainer ref={scrollContainerRef}>
          {similarProperties.map((property) => (
            <PropertyCard
              key={property._id}
              minWidth={getCardMinWidth()}
              onClick={() => {
                navigate(`/view-property5/${property._id}`);
              }}
            >
              <PropertyImage
                src={
                  property.files && property.files.length > 0
                    ? property.files[0]
                    : "/default-image.jpg"
                }
                alt={property.propertyName}
              />
              <Overlay className="overlay">View Property</Overlay>
              {/* <HeartIcon
                liked={property.liked}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleHeart(property._id);
                }}
              /> */}
              <PropertyDetails>
                <PropertyTitle>{property.propertyName}</PropertyTitle>
                <PropertyInfo>Budget:₹{property.budget} {property.budgetMethod}</PropertyInfo>
                {/* <PropertyInfo>Area: {property.numberOfSqFeet} sq.ft</PropertyInfo>
                <PropertyInfo>Rooms: {property.numberOfRooms}</PropertyInfo> */}
                <PropertyInfo>Types: {property.types}</PropertyInfo>
                <PropertyInfo>Location: {property.city}</PropertyInfo>
              </PropertyDetails>
            </PropertyCard>
          ))}
        </ScrollContainer>
        <ArrowButton direction="right" onClick={() => scroll("right")}>
          &#9654;
        </ArrowButton>
      </Container>
    )
  );
};

export default SimilarProperties;
