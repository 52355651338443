// TermsAndConditions.js
import React from 'react';
import styled from 'styled-components';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.light};
  color: ${({ theme }) => theme.colors.dark};
  line-height: 1.6;
  min-height: 100vh; /* Ensure the container takes at least full viewport height */
  box-sizing: border-box; /* Include padding in the element's total width and height */
  @media (max-width: 768px) {
    padding: 10px;
  }
`;
const Content = styled.div`
  max-width: 800px; /* Adjust the max-width to fit your design */
  width: 100%;
`;
const Section = styled.section`
  margin-bottom: 20px;
`;
const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.medium};
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;
const SubTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.medium};
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;
const Paragraph = styled.p`
  font-size: 1rem;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;
const TermsAndConditions = () => {
  return (
    <Container>
      <Content>
        <Title>Terms and Conditions</Title>
        <Section>
          <SubTitle>1. Acceptance</SubTitle>
          <Paragraph>
            By using our services, you agree to these terms. If you do not agree, please refrain from using our services.
          </Paragraph>
        </Section>
        <Section>
          <SubTitle>2. Use of Service</SubTitle>
          <Paragraph>
            You must use our services only for lawful purposes. You are responsible for any activity that occurs under your account.
          </Paragraph>
        </Section>
        <Section>
          <SubTitle>3. Intellectual Property</SubTitle>
          <Paragraph>
            All content and materials are owned by us or our licensors. You may not copy, modify, or distribute any content without permission.
          </Paragraph>
        </Section>
        <Section>
          <SubTitle>4. Limitation of Liability</SubTitle>
          <Paragraph>
            We are not liable for any indirect, incidental, or consequential damages arising from your use of our services.
          </Paragraph>
        </Section>
        <Section>
          <SubTitle>5. Changes</SubTitle>
          <Paragraph>
            We may modify these terms at any time. Continued use of our services signifies your acceptance of the updated terms.
          </Paragraph>
        </Section>
        <Section>
          <SubTitle>6. Governing Law</SubTitle>
          <Paragraph>
            These terms are governed by and construed in accordance with the laws of [Your Jurisdiction].
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};
export default TermsAndConditions;








