import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faHeart, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import useMediaQuery from '../../useMediaQuery';
import Loader from '../Loader';
// Styled components
const FullscreenModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
const ModalImage = styled.img`
  max-width: 90%;
  max-height: 80%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
`;
const ModalCloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: white;
`;
const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
  ${(props) => (props.direction === 'left' ? 'left: 20px;' : 'right: 20px;')}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  color: white;
  //z-index: 100;
`;
const Container = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  background-color: #e7e1d4;
  flex-direction: row; /* Changed to row to display items in a row */
  @media (max-width: 768px) {
    flex-direction: column; /* Keep column layout for mobile */
  }
`;
const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const LargeImageContainer = styled.div`
  position: relative;
  width: 50%;
  height: 500px; /* Set a fixed height */
  overflow: hidden; /* Prevent overflow */
  @media(max-width: 768px) {
    width: 100%;
  }
`;
const LargeImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
  }
`;
const SmallImagesContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  @media(min-width: 768px) {
    flex-direction: column;
  }
`;
const SmallImage = styled.div`
  position: relative;
  width: 100px;
  height: 70px;
  border-radius: 10px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  ${(props) =>
        props.showOverlay &&
        `
  &::after {
    content: '+${props.overlayText}';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-radius: 10px;
    backdrop-filter: blur(5px);
  }
`}
`;
const PropertyDetailsContainer = styled.div`
  flex: 2;
  background-color: #ffffff; /* Subtle background for separation */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between; /* Adjusts spacing between buttons */
  align-items: center; /* Aligns buttons vertically in the center */
  margin-top: 20px;
  gap: 10px; /* Adds space between buttons */
  flex-wrap: nowrap; /* Prevents buttons from wrapping onto the next line */
  
  @media(max-width: 768px) {
    display: grid;
    flex-wrap: wrap; /* Allow wrapping for smaller screens if needed */
  }
`;
const Button = styled.button`
  background-color: #92443a;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  &:hover {
    background-color: #a65450;
    transform: translateY(-2px);
  }
  svg {
    margin-right: 8px;  // Margin for icon
  }
  @media (max-width: 768px) {
    padding: 8px 15px;
    font-size: 14px;
  }
`;
const Detail = styled.p`
  margin: 10px 0;
  font-size: 18px;
  font-weight: 600; /* Adjust font weight for better readability */
  color: #333;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
`;
const BoldLabel = styled.span`
  font-weight: bold;
  color: #92443A; /* Add custom color for labels */
  font-size: 19px;
`;
const HeartIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  color: ${(props) => (props.isActive ? 'red' : 'gray')};
  cursor: pointer;
`;
const ApprovalButton = styled(Button)`
  background: #28A745; /* Green background for approval */
  color: white; /* White text color */
`;
const IndividualAdmin = () => {
    const { propertyId } = useParams();
    const navigate = useNavigate();
    const [property, setProperty] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isHeartActive, setIsHeartActive] = useState(false);
    const touchStartX = useRef(null);
    const isTabletOrMobile = useMediaQuery('(max-width: 768px)');
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    useEffect(() => {
        const fetchPropertyDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/properties/${propertyId}`);
                setProperty(response.data);
                setIsHeartActive(response.data.isHeartActive);
            } catch (error) {
                console.error('Error fetching property details:', error);
                setError('Failed to load property details.');
            } finally {
                setLoading(false);
            }
        };
        fetchPropertyDetails();
    }, [propertyId]);
    if (loading) return <Loader/>;
    if (error) return <p>{error}</p>;
    if (!property) return <p>No property details found.</p>;
    const { files = [] } = property;
    const remainingImagesCount = files.length - 3;
    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === files.length - 1 ? 0 : prevIndex + 1));
    };
    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? files.length - 1 : prevIndex - 1));
    };
    const openModal = (index) => {
        setCurrentImageIndex(index);
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
    };
    const handleTouchEnd = (e) => {
        if (touchStartX.current === null) return;
        const touchEndX = e.changedTouches[0].clientX;
        if (touchStartX.current - touchEndX > 50) {
            nextImage();
        } else if (touchStartX.current - touchEndX < -50) {
            prevImage();
        }
        touchStartX.current = null;
    };
    const deleteProperty = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/properties/${propertyId}`);
            navigate('/adminhome');
        } catch (error) {
            console.error('Error deleting property:', error.response || error.message || error);
            setError('Failed to delete property.');
        }
    };
    const handleEdit = (property) => {
        // Navigate to the edit page with the property state
        navigate(`/editProperty/${property._id}`, { state: { property } });
    };
    const handleApproval = async () => {
        try {
          const { _id: propertyId } = property; // Get property ID from the property object
          const response = await fetch(`${process.env.REACT_APP_API_URL}/approve-property`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ propertyId }), // Send the propertyId to the backend
          });
          if (!response.ok) {
            throw new Error('Failed to approve property');
          }
          const result = await response.json();
          alert(result.message); // Display success or failure message
          navigate('/AdminHome')
        } catch (error) {
          console.error('Error approving property:', error);
          alert('Error approving property. Please try again.');
        }
      };
    return (
        <Container>
            <ImagesWrapper>
                <SmallImagesContainer>
                    {files.slice(0, 3).map((image, index) => (
                        <SmallImage key={index} onClick={() => openModal(index)}>
                            <img src={image} alt={`Property ${index + 1}`} />
                        </SmallImage>
                    ))}
                    {remainingImagesCount > 0 && (
                        <SmallImage
                            overlayText={remainingImagesCount}
                            showOverlay={true}
                            onClick={() => openModal(3)}
                        >
                            <img src={files[3]} alt={`Remaining ${remainingImagesCount} images`} />
                        </SmallImage>
                    )}
                </SmallImagesContainer>
            </ImagesWrapper>
            <LargeImageContainer onClick={() => openModal(currentImageIndex)}>
                <Arrow direction="left" onClick={(e) => { e.stopPropagation(); prevImage(); }}>
                    &#10094;
                </Arrow>
                <LargeImage src={files[currentImageIndex]} alt="Property View" />
                <Arrow direction="right" onClick={(e) => { e.stopPropagation(); nextImage(); }}>
                    &#10095;
                </Arrow>
            </LargeImageContainer>
            <PropertyDetailsContainer>
            <Detail><BoldLabel>Code:</BoldLabel> {property.code ? property.code:'Not mentioned'}</Detail>
                <Detail><BoldLabel>Name:</BoldLabel> {property.propertyName ? property.propertyName : 'Not Mentioned'}</Detail>
                <Detail><BoldLabel>Property Type:</BoldLabel> {property.propertyType }</Detail>
                <Detail><BoldLabel>Types:</BoldLabel> {property.types}</Detail>
                <Detail><BoldLabel>Length:</BoldLabel> {property.length ? property.length :'Not Mentioned'}</Detail>
                <Detail><BoldLabel>Breadth:</BoldLabel> {property.breadth ? property.breadth :'Not Mentioned'}</Detail>
                <Detail><BoldLabel>Size:</BoldLabel> {property.numberOfSqFeet ? property.numberOfSqFeet:'Not Mentioned'} </Detail>
                <Detail><BoldLabel>Cent:</BoldLabel> {property.numberOfCent ? property.numberOfCent:'Not Mentioned'} </Detail>
                <Detail><BoldLabel>Acre:</BoldLabel> {property.numberOfAcre ? property.numberOfAcre:'Not Mentioned'} </Detail>
                <Detail><BoldLabel>Build Up Area (Sq):</BoldLabel> {property.buildUpAreaSqFt ? property.buildUpAreaSqFt : 'Not Mentioned'} </Detail>
                <Detail><BoldLabel>Build Up Area (Cent):</BoldLabel> {property.buildUpAreaCent ? property.buildUpAreaCent : 'Not Mentioned'} </Detail>
                <Detail><BoldLabel>Build Up Area (Acre):</BoldLabel> {property.buildUpAreaAcre ? property.buildUpAreaAcre : 'Not Mentioned'} </Detail>
                <Detail><BoldLabel>Age:</BoldLabel> {property.ageOfProperty ? property.ageOfProperty : "Not Mentioned"} years</Detail>
                <Detail><BoldLabel>Budget:</BoldLabel> ₹{property.budget} {property.budgetMethod}</Detail>
                <Detail><BoldLabel>Bathrooms:</BoldLabel> {property.numberOfBathrooms ? property.numberOfBathrooms : 'Not Mentioned'}</Detail>
                <Detail><BoldLabel>Rooms:</BoldLabel> {property.numberOfRooms ? property.numberOfRooms :'Not Mentioned'}</Detail>
                <Detail><BoldLabel>Parking:</BoldLabel> {property.parking ? property.parking : 'Not Mentioned'}</Detail>
                <Detail><BoldLabel>Lift:</BoldLabel> {property.lift ? property.lift : 'Not Mentioned'}</Detail>
                <Detail><BoldLabel>Electricity:</BoldLabel> {property.electricity ? property.electricity : "Not Mentioned"}</Detail>
                <Detail><BoldLabel>Water Facility:</BoldLabel> {property.waterFacility ? property.waterFacility :'Not Mentioned'}</Detail>
                <Detail><BoldLabel>Road Facility:</BoldLabel> {property.roadFacility ? property.roadFacility :'Not Mentioned'}</Detail>
                <Detail><BoldLabel>Bore Well:</BoldLabel> {property.boreWell ? property.boreWell : "Not Mentioned"}</Detail>
                <Detail><BoldLabel>Amenities:</BoldLabel> {property.amenities ? property.amenities : 'Not Mentioned'}</Detail>
                <Detail><BoldLabel>Contact Owner Name:</BoldLabel> {property.contactOwnerName}</Detail>
                <Detail><BoldLabel>Phone Number:</BoldLabel> {property.contactOwnerPhone}</Detail>
                <Detail><BoldLabel>Address:</BoldLabel> {property.propertyAddress}</Detail>
                <Detail><BoldLabel>City:</BoldLabel> {property.city}</Detail>
                <Detail><BoldLabel>State:</BoldLabel> {property.state}</Detail>
                <Detail><BoldLabel>Zip Code:</BoldLabel> {property.zipCode}</Detail>
                <Detail><BoldLabel>Status:</BoldLabel> {property.status}</Detail>
                <Detail><BoldLabel>Created By:</BoldLabel> {property.createdBy}</Detail>
                <ButtonGroup>
                    {/* <Button>
            <FontAwesomeIcon icon={faShareAlt} style={{ marginRight: '8px' }} />
            Share
          </Button> */}
                    {/* <Button onClick={toggleHeart}>
            <FontAwesomeIcon icon={faHeart} style={{ marginRight: '8px' }} />
            {isHeartActive ? 'Wishlisted' : 'Wishlist'}
          </Button> */}
                    <ApprovalButton onClick={handleApproval}>
                        <FontAwesomeIcon icon={faHeart} style={{ marginRight: '8px' }} />
                        Approval
                    </ApprovalButton>
                    <Button onClick={() => handleEdit(property)}>
                        <FontAwesomeIcon icon={faEdit} style={{ marginRight: '8px' }} />
                        Edit
                    </Button>
                    <Button onClick={deleteProperty}>
                        <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '8px' }} />
                        Delete
                    </Button>
                </ButtonGroup>
            </PropertyDetailsContainer>
            {isModalOpen && (
                <FullscreenModal onClick={closeModal} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                    <ModalImage src={files[currentImageIndex]} alt="Fullscreen Property View" />
                    <ModalCloseButton onClick={closeModal}>×</ModalCloseButton>
                    <Arrow direction="left" onClick={(e) => { e.stopPropagation(); prevImage(); }}>
                        &#10094;
                    </Arrow>
                    <Arrow direction="right" onClick={(e) => { e.stopPropagation(); nextImage(); }}>
                        &#10095;
                    </Arrow>
                </FullscreenModal>
            )}
        </Container>
    );
};
export default IndividualAdmin;
