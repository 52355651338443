import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import CustomerDetails from './CustomerDetails';
import ApproveProperty from './ApprovePage';
import UploadImage from './UploadImage';
import PropertyListingAdmin from './PropertyList';
import Plans from '../Plan';
import Sell from '../Sell';
const AdminHome = () => {
    const [propertyCount, setPropertyCount] = useState(0);
    const [customerCount, setCustomerCount] = useState(0);
    const [approvedCount, setApprovedCount] = useState(0);
    const [selectedView, setSelectedView] = useState('');  // New state for selected view
    // Fetch counts from the backend
    const fetchCounts = async () => {
        try {
            const [propertyResponse, approvedResponse, customerResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_API_URL}/propertyCount`),
                axios.get(`${process.env.REACT_APP_API_URL}/propertyCount1`),
                axios.get(`${process.env.REACT_APP_API_URL}/customerCount`),
            ]);
            setPropertyCount(propertyResponse.data.count);
            setApprovedCount(approvedResponse.data.count);
            setCustomerCount(customerResponse.data.count);
        } catch (error) {
            console.error('Error fetching counts:', error);
        }
    };
    useEffect(() => {
        fetchCounts();
    }, []);
    // Handlers for setting the selected view
    const handleCustomerDetails = () => {
        setSelectedView('customerDetails');
    };
    const handleApproveProperties = () => {
        setSelectedView('approveProperties');
    };
    const handleApprovedProperties = () => {
        setSelectedView('approvedProperties');
    };
    const handleUploadImage = () => {
        setSelectedView('uploadImage');
    };

    const handlePremiumPlans = () => {
        setSelectedView('plans');
    }

    const handleSell = () => {
        setSelectedView('sell');
    }

    
    return (
        <PageLayout>
            <Sidebar>
                <Title>Admin Dashboard</Title>
                <ButtonContainer>
                    <Button
                        onClick={handleCustomerDetails}
                        bgColor={selectedView === 'customerDetails' ? '#007BFF' : '#28A745'}  // Change to blue when selected
                    >
                        Customers Details {customerCount > 0 && `(${customerCount})`}
                    </Button>
                    <Button
                        onClick={handleApproveProperties}
                        bgColor={selectedView === 'approveProperties' ? '#007BFF' : '#28A745'}  // Change to blue when selected
                    >
                        Pending Properties {propertyCount > 0 && `(${propertyCount})`}
                    </Button>
                    <Button
                        onClick={handleApprovedProperties}
                        bgColor={selectedView === 'approvedProperties' ? '#007BFF' : '#28A745'}  // Change to blue when selected
                    >
                        Approved Properties {approvedCount > 0 && `(${approvedCount})`}
                    </Button>
                    <Button
                        onClick={handlePremiumPlans}
                        bgColor={selectedView === 'plans' ? '#007BFF' : '#28A745'}  // Change to blue when selected
                    >
                        Premium Plans 
                    </Button>
                    <Button
                        onClick={handleUploadImage}
                        bgColor={selectedView === 'uploadImage' ? '#007BFF' : '#28A745'}  // Change to blue when selected
                    >
                        Upload Image
                    </Button>
                    <Button
                        onClick={handleSell}
                        bgColor={selectedView === 'sell' ? '#007BFF' : '#28A745'}  // Change to blue when selected
                    >
                        Sell Page
                    </Button>
                </ButtonContainer>
            </Sidebar>
            <ContentWrapper>
                {selectedView === 'customerDetails' && <CustomerDetails />}
                {selectedView === 'approveProperties' && <PropertyListingAdmin/>}
                {selectedView === 'approvedProperties' && <ApproveProperty setSelectedView={setSelectedView} />}
                {/* {selectedView === 'approvedProperties' && <ApproveProperty/>} */}
                {selectedView === 'uploadImage' && <UploadImage/>}
                {selectedView === 'plans' && <Plans/>}
                {selectedView === 'sell' && <Sell/>}
                {!selectedView && (
                    <ImageContainer>
                    <StyledImage src='https://cdn.shopify.com/s/files/1/0647/9953/9386/files/Admin1.png?v=1728538000' />
                </ImageContainer>
                
                )}
            </ContentWrapper>
        </PageLayout>
    );
};
// Styled Components (same as before)
const PageLayout = styled.div`
    display: flex;
    min-height: 100vh;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
const Sidebar = styled.div`
    width: 250px;
    background-color: #F4F4F4;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    @media (max-width: 768px) {
        width: 100%;
        height: auto;
        position: relative;
        padding: 10px;
    }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;  // Change to start the content from the top
  flex-grow: 1;
  padding: 20px;  // Optional: Add padding for better spacing
  
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
const Title = styled.h1`
    margin-bottom: 20px;
    color: #333;
    font-size: 1.8rem;
    text-align: left;
    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
`;
const Button = styled.button`
    padding: 15px 20px;
    font-size: 1rem;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: ${({ bgColor }) => bgColor};
    width: 100%;
    &:hover {
        filter: brightness(0.9);
        transform: translateY(-2px);
    }
    &:active {
        transform: translateY(0);
    }
    @media (max-width: 768px) {
        padding: 10px;
        font-size: 0.9rem;
    }
`;

const ImageContainer = styled.div`
    width: 100%;
    height: 600px; /* This ensures a fixed height, you can adjust it as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensures no overflow if the image exceeds container size *
    
`;

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container without stretching */
    
`;
export default AdminHome;