import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebaseConfig';
import axios from 'axios';
const UploadImage = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [bannerImages, setBannerImages] = useState([]);
  const [replacingImage, setReplacingImage] = useState({});
  const [replacePreviewImages, setReplacePreviewImages] = useState({});
  useEffect(() => {
    fetchBannerImages();
  }, []);
  const fetchBannerImages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/bannerImage`);
      setBannerImages(response.data);
    } catch (error) {
      console.error('Error fetching banner images:', error);
    }
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };
  const handleUpload = () => {
    if (!selectedImage) return;
    const storageRef = ref(storage, `banners/${Date.now()}-${selectedImage.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedImage);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error('Error uploading image:', error);
        alert('Failed to upload image.');
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          alert('Image uploaded successfully!');
          const yourBannerId = '66de989830609e5b55c1c9d1'; // Use your specific banner ID here
          axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, {
            id: yourBannerId,
            imageUrl: downloadURL,
            alt: 'Updated Banner Image',
          })
            .then(() => {
              alert('Banner updated in the database successfully!');
              fetchBannerImages();
              setPreviewImage(null); // Clear preview after upload
            })
            .catch((error) => {
              console.error('Error updating banner in the database:', error);
              alert('Failed to update banner in the database.');
            });
        });
      }
    );
  };
  const handleDeleteImage = async (bannerId, imageUrl) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/upload/delete/${bannerId}/${encodeURIComponent(imageUrl)}`);
      alert('Image deleted successfully!');
      fetchBannerImages(); // Refresh the list of images after deletion
    } catch (error) {
      console.error('Error deleting image:', error);
      alert('Failed to delete image.');
    }
  };
  const handleReplaceImageChange = (event, bannerId, imageUrl) => {
    const file = event.target.files[0];
    if (file) {
      setReplacingImage({ bannerId, imageUrl, file });
      setReplacePreviewImages((prevState) => ({
        ...prevState,
        [bannerId]: URL.createObjectURL(file), // Set the preview image for the specific banner
      }));
    }
  };
  const handleReplaceUpload = () => {
    if (!replacingImage || !replacingImage.file) return;
    const { bannerId, imageUrl, file } = replacingImage;
    const storageRef = ref(storage, `banners/${Date.now()}-${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error('Error uploading image:', error);
        alert('Failed to upload image.');
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          alert('Image uploaded successfully!');
          axios.post(`${process.env.REACT_APP_API_URL}/api/replace-image`, {
            id: bannerId,
            oldImageUrl: imageUrl,
            newImageUrl: downloadURL,
            alt: 'Updated Banner Image'
          })
            .then(() => {
              alert('Banner image replaced successfully in the database!');
              fetchBannerImages();
              setReplacePreviewImages((prevState) => ({
                ...prevState,
                [bannerId]: null, // Clear the replace preview after upload
              }));
            })
            .catch((error) => {
              console.error('Error updating banner in the database:', error);
              alert('Failed to update banner in the database.');
            });
        });
      }
    );
  };
  return (
    <Container>
      <Title>Banner Image</Title>
      {/* Section for adding new image */}
      <HorizontalWrapper>
        <Subtitle>Add New Banner Image (Size : Height: 3456 px , Width: 6912 px)</Subtitle>
        <PlusIcon onClick={() => document.getElementById('imageInput').click()}>+ <Medium>Add Banner</Medium></PlusIcon>
      </HorizontalWrapper>
      <HiddenInput
        id="imageInput"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
      />
      {previewImage && (
        <PreviewSection>
          <Subtitle>Image Preview:</Subtitle>
          <ImagePreview src={previewImage} alt="Preview" />
          {progress > 0 && <Progress>Upload Progress: {Math.round(progress)}%</Progress>}
          <ButtonContainer>
            <Button onClick={handleUpload}>Upload Image</Button>
          </ButtonContainer>
        </PreviewSection>
      )}
      {/* Section for displaying and replacing existing banner images */}
      <Subtitle>Uploaded Banner Images:</Subtitle>
      {bannerImages.length > 0 ? (
        bannerImages.map((banner, index) => (
          <ImageContainer key={index}>
            {banner.imageUrl.map((image, imgIndex) => (
              <div key={imgIndex}>
                <ImagePreview src={image} alt={banner.alt} />
                <AltText>{banner.alt}</AltText>
                <HorizontalWrapper>
                  {banner.imageUrl.length > 2 && (
                    <Button onClick={() => handleDeleteImage(banner._id, image)}>Delete</Button>
                  )}
                  <HiddenInput
                    id={`replaceImage-${banner._id}-${imgIndex}`}
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleReplaceImageChange(e, banner._id, image)}
                  />
                  <PlusIcon onClick={() => document.getElementById(`replaceImage-${banner._id}-${imgIndex}`).click()}>
                    +<Medium>Replace Banner</Medium>
                  </PlusIcon>
                </HorizontalWrapper>
                {replacePreviewImages[banner._id] && (
                  <PreviewSection>
                    <Subtitle>Replace Image Preview:</Subtitle>
                    <ImagePreview src={replacePreviewImages[banner._id]} alt="Replace Preview" />
                    {progress > 0 && <Progress>Upload Progress: {Math.round(progress)}%</Progress>}
                    <ButtonContainer>
                    <Button onClick={handleReplaceUpload}>Replace Image</Button>
                    </ButtonContainer>
                  </PreviewSection>
                )}
              </div>
            ))}
          </ImageContainer>
        ))
      ) : (
        <NoImagesText>No banner images available.</NoImagesText>
      )}
    </Container>
  );
};
export default UploadImage;
// Styled Components
const Container = styled.div`
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  background-color: #F9F9F9;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;
// Wrapping the PlusIcon and other elements to align them horizontally
const HorizontalWrapper = styled.div`
  display: flex;
  align-items: center; /* Align vertically */
  gap: 20px; /* Add space between elements */
  margin-bottom: 20px;
`;
const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
`;
const Subtitle = styled.h3`
  font-size: 1.5rem;
  color: #666;
`;
const PlusIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: darkgreen;
  }
`;
const Medium = styled.span`
  margin-left: 10px; /* Spacing between the "+" sign and the text */
  font-size: 16px;
  font-weight: 500;
`;
const HiddenInput = styled.input`
  display: none;
`;
const ButtonContainer = styled.div`
  display: flex;        /* Enable flexbox */
  justify-content: flex-start; /* Align items to the right */
  margin-top: 10px;    /* Add some space above the button container */
`;
const Button = styled.button`
  padding: 13px 20px;
  font-size: 1rem;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #45A049;
  }
`;
const ImagePreview = styled.img`
  width: 300px;
  height: auto;
  margin: 10px 0;
  border-radius: 10px;
`;
const AltText = styled.p`
  font-size: 1rem;
  color: #333;
`;
const PreviewSection = styled.div`
  margin-top: 20px;
`;
const Progress = styled.div`
  font-size: 1rem;
  color: #333;
`;
const ImageContainer = styled.div`
  margin: 20px 0;
`;
const NoImagesText = styled.p`
  font-size: 1.2rem;
  margin-top: 20px;
  color: #999;
`;
