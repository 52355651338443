import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Eye icons

// Styled components
const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  padding: 40px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center; 
  align-items: center;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevents horizontal overflow */
`;

const ForgotBox = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 500px; 
  width: 100%;
  margin-top: -50px; /* Moves the container slightly upwards */
`;

const ForgotTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

const TitleText = styled.h1`
  font-size: 26px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.dark};
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Label = styled.label`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.medium};
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  max-width: 500px;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: border-color 0.2s ease-in-out;
  
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: none;
  }
`;

const ErrorText = styled.p`
  color: red;
  margin-bottom: 10px;
  font-size: 14px;
`;

const BottomTextContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  font-size: 18px;
  font-weight: bold;
  padding: 12px 25px;
  background-color: ${({ theme }) => theme.colors.medium};
  color: ${({ theme }) => theme.colors.light};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin: 20px 0;
  width: 100%;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;

const SignupButton = styled.button`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 10px;
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.dark};
  }
`;

const OrText = styled.p`
  margin: 15px 0;
  color: ${({ theme }) => theme.colors.medium};
  font-size: 16px;
`;

const EyeIcon = styled.div`
  position: absolute;
  top: 40%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;

const Forgot = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  // Set email from the state passed via navigate
  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    } else {
      setEmail('');
    }
  }, [location.state]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmit = async () => {
    let valid = true;

    if (!newPassword) {
      setPasswordError("Please fill the password field");
      valid = false;
    } else {
      setPasswordError('');
    }

    if (!valid) {
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, newPassword }), 
      });

      if (response.ok) {
        alert('Password reset successful');
        navigate('/signin');
      } else {
        const errorData = await response.json();
        alert(`Failed to reset password: ${errorData.message || 'Unknown error'}`);
      }
    } catch (error) {
      alert('Server error');
    }
  };

  return (
    <Container>
      <ForgotBox>
        <ForgotTitle>
          <TitleText>Forgot Password</TitleText>
        </ForgotTitle>

        <FormWrapper>
          <Label>Email</Label>
          {emailError && <ErrorText>{emailError}</ErrorText>}
          <Input 
            type="email" 
            value={email} 
            readOnly 
          />

          <Label>Enter New Password</Label>
          {passwordError && <ErrorText>{passwordError}</ErrorText>}
          <InputWrapper>
            <Input
              type={showPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <EyeIcon onClick={togglePasswordVisibility}>
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </EyeIcon>
          </InputWrapper>
        </FormWrapper>

        <BottomTextContainer>
          <SubmitButton onClick={handleSubmit}>Save</SubmitButton>
          <OrText>(OR)</OrText>
          <SignupButton onClick={() => navigate('/Signup')}>Signup</SignupButton>
        </BottomTextContainer>
      </ForgotBox>
    </Container>
  );
};

export default Forgot;
