import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useAuth } from '../../AuthProvider';
const SigninContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('https://cdn.shopify.com/s/files/1/0851/4607/5431/files/Untitled_design_20.png?v=1725961182');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
const SigninBox = styled.div`
  background-color: rgba(255, 255, 255, 0.1); /* Transparent background */
  backdrop-filter: blur(10px); /* Creates a glassy effect */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Softer shadow */
  width: 300px;
  text-align: center;
  border: 2px solid rgba(255, 255, 255, 0.5); /* Adds a mirror-like border */
  color: white; /* For better visibility of text on glass */
  position:relative;
  bottom:30px;
`;
const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const Input = styled.input`
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.extralight};
`;
const EyeIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: ${({ theme }) => theme.colors.medium};
`;
const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.colors.medium};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;
const ForgotPassword = styled.div`
  text-align: left;
  margin-top: 10px;
  font-size: 12px;
  cursor: pointer;
  color: #007BFF;
  &:hover {
    text-decoration: underline;
  }
`;
const Error = styled.div`
  color: red;
  margin-bottom: 15px;
`;
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ModalBox = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
`;
const ModalButton = styled.button`
  background-color: ${({ theme }) => theme.colors.medium};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin-top: 15px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;
const Signin = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [modalContent, setModalContent] = useState(''); // State to hold modal content
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const { login } = useAuth();
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/signin`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });
      const result = await response.json();
      if (response.ok) {
        const userDetails = {
          firstName: result.firstName,
          lastName: result.lastName,
          email: result.email,
          phoneNumber: result.phoneNumber,
          isAdmin: result.email === 'akshayalands.tup@gmail.com', // Check if the email belongs to admin
        };
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        login(userDetails);
        // Show different modal content based on admin status
        if (userDetails.isAdmin) {
          setModalContent('Admin Login Successfully! Welcome');
        } else {
          setModalContent('Login Successfully! Welcome to AkshayaLands');
        }
        setIsModalOpen(true); // Show modal on successful login
      } else {
        setError(result.message || 'Login failed');
        setModalContent('Login failed. Please check your credentials and try again.');
        setIsModalOpen(true); // Show modal for failed login
      }
    } catch (error) {
      setError('Login failed');
      setModalContent('Login failed due to a network issue. Please try again later.');
      setIsModalOpen(true); // Show modal for network failure
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    if (!error) {
      navigate('/Common/Sell'); 
    }
  };
  return (
    <SigninContainer>
      <SigninBox>
        <h2>Log In</h2>
        {error && <Error>{error}</Error>}
        <form onSubmit={handleLogin}>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <InputWrapper>
            <Input
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <EyeIcon onClick={togglePasswordVisibility}>
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </EyeIcon>
          </InputWrapper>
          <ForgotPassword onClick={() => navigate('/forgotpassword', { state: { email } })}>
            Forgot Password?
          </ForgotPassword>
          <Button type="submit">Login</Button>
          <Button type="button" onClick={() => navigate('/signup')}>Sign Up</Button>
        </form>
      </SigninBox>
      {/* Modal for login success or failure */}
      {isModalOpen && (
        <ModalOverlay>
          <ModalBox>
            <h3>{modalContent.includes('failed') ? 'Error' : 'Success'}</h3>
            <p>{modalContent}</p>
            <ModalButton onClick={closeModal}>Close</ModalButton>
          </ModalBox>
        </ModalOverlay>
      )}
    </SigninContainer>
  );
};
export default Signin;