import React, { useState } from 'react';
import styled from 'styled-components';
const FacilitiesContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
`;
const Heading = styled.h2`
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
`;
const MainContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
const MainImage = styled.div`
  flex: 1;
  position: relative;
  img {
    width: 100%;
    height: auto;
    max-height: 600px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;
const SideImages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
    overflow-x: auto;
    padding: 10px 0;
    width: 100%;
  }
`;
const Circle = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  border: 3px solid transparent;
  transition: border 0.3s ease;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.active {
    border-color: #FF5722;
  }
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;
const ErrorMessage = styled.p`
  color: red;
  font-size: 1.2em;
  margin-top: 20px;
`;
const Facilities = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  // Static array of image data (hardcoded data)
  const images = [
    {
      _id: "66debbb230609e5b55c1c9d3",
      Name: "Metro",
      image: "https://cdn.shopify.com/s/files/1/0851/4607/5431/files/14.jpg?v=1726810631"
    },
    {
      _id: "66ed0afb2d98fbb9b82d2118",
      Name: "Hospital",
      image: "https://cdn.shopify.com/s/files/1/0851/4607/5431/files/16.jpg?v=1726810631"
    },
    {
      _id: "66ed0b202d98fbb9b82d2119",
      Name: "Bus",
      image: "https://cdn.shopify.com/s/files/1/0851/4607/5431/files/13.jpg?v=1726810634"
    },
    {
      _id: "66ed0b492d98fbb9b82d211a",
      Name: "School",
      image: "https://cdn.shopify.com/s/files/1/0851/4607/5431/files/18.jpg?v=1726810631"
    },
    {
      _id: "66ed0b492d98fbb9b82d211a",
      Name: "Shopping",
      image: "https://cdn.shopify.com/s/files/1/0647/9953/9386/files/Akshaya_Lands_002_1.jpg?v=1727338670"
    },
  ];
  const handleImageClick = (index) => {
    setActiveIndex(index);
  };
  return (
    <FacilitiesContainer>
      <Heading>Facilities</Heading>
      <MainContent>
        <MainImage>
          <img src={images[activeIndex].image} alt={images[activeIndex].Name} />
        </MainImage>
        <SideImages>
          {images.map((image, index) => (
            <Circle
              key={image._id}
              className={activeIndex === index ? 'active' : ''}
              onClick={() => handleImageClick(index)}
            >
              <img src={image.image} alt={image.Name} />
            </Circle>
          ))}
        </SideImages>
      </MainContent>
    </FacilitiesContainer>
  );
};
export default Facilities;