// AuthProvider.js
import React, { createContext, useContext, useState } from 'react';
import { useWishlist } from './WishlistContext';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    JSON.parse(localStorage.getItem('isAuthenticated')) || false
  );
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem('userDetails')) || null
  );
  const { setWishlist } = useWishlist();

  const login = (user) => {
    setIsAuthenticated(true);
    setUserDetails(user);
    localStorage.setItem('isAuthenticated', JSON.stringify(true));
    localStorage.setItem('userDetails', JSON.stringify(user)); // Store user details
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserDetails(null);
    localStorage.setItem('isAuthenticated', JSON.stringify(false));
    localStorage.removeItem('userDetails'); // Clear user details
  };
  const fetchWishlistFromServer = async (email) => {
    try {
      const response = await fetch(`http://localhost:3007/api/wishlist?email=${email}`);
      if (!response.ok) {
        throw new Error('Failed to fetch wishlist');
      }
      const data = await response.json();
      return data.properties || [];
    } catch (error) {
      console.error('Error fetching wishlist:', error);
      return [];
    }
  };
  const value = {
    isAuthenticated,
    userDetails,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
