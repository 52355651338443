import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Loader from '../Loader';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.light};
  overflow-x: hidden;
  @media (max-width: 1024px) {
    flex-direction: column;
    min-height: 100%;
  }
`;

export const FiltersSection = styled.div`
  width: 300px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  @media (max-width: 1024px) {
  display:none
  }
`;

export const FilterItem = styled.div`
  margin-bottom: 20px;
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 5px;
  }
`;

export const Button = styled.button`
  background-color: ${props => props.active ? '#B87759' : '#D4D4D4'};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 1 1 auto;
  max-width: 120px;
  &:hover {
    background-color: ${props => props.active ? '#A4694F' : '#C1C1C1'};
  }
`;

export const ApplyButton = styled.button`
  background-color: #B87759;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  &:hover {
    background-color: #A4694F;
  }
  @media (max-width: 768px) {
    width: calc(100% - 30px);
    margin: 10px auto;
  }
`;

export const ListingSection = styled.div`
  flex: 1;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.light};
  min-height: 100vh;
  @media (max-width: 1024px) {
    padding: 15px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const Image = styled.img`
  width: 100%;
  max-width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  @media (max-width: 768px) {
    height: 180px;
  }
`;

export const Image1 = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image covers the area while maintaining its aspect ratio */

  @media (max-width: 768px) {
    display: none; /* Hides the image completely on mobile view */
    margin: 0;    /* Ensures no margin is applied */
    padding: 0;   /* Ensures no padding is applied */
  }
`;


export const Description = styled.p`
  margin: 10px 0;
`;

export const ViewButton = styled.button`
  background-color: ${({ theme }) => theme.colors.medium};
  color: ${({ theme }) => theme.colors.light};
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;

export const Dropdown = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
`;

const NoPropertyMessage = styled.p`
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
  color: ${({ theme }) => theme.colors.dark}; /* Adjust color if needed */
`;

const SearchRent = () => {
  const { propertyName, city } = useParams();
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [types, setTypes] = useState('all');
  const [loading ,setLoading] = useState(true);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    bedrooms: "1 BHK",
    location: ["OMR", "Avadi"],
  });

  const handleViewDetails = (propertyId) => {
    navigate(`/view-property5/${propertyId}`);
  };

  const propertyList = async (filterParams) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/propertyListSearch`, {
        params: { propertyName, city, ...filterParams },
      });
      setList(response.data);
      setFilteredList(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    propertyList(filters);
  }, [filters, propertyName, city]);

  useEffect(() => {
    if (types === 'all') {
      setFilteredList(list);
    } else {
      const filtered = list.filter(item => item.types && item.types.toLowerCase() === types.toLowerCase());
      setFilteredList(filtered);
    }
  }, [types, list]);

  const handleFilterChange = (filterType, value) => {
    if (filterType === 'location') {
      const newLocations = filters.location.includes(value)
        ? filters.location.filter(loc => loc !== value)
        : [...filters.location, value];
      setFilters({ ...filters, location: newLocations });
    } else {
      setFilters({ ...filters, [filterType]: value });
    }
  };

  const applyFilters = () => {
    propertyList(filters);
  };

  if (loading) return <Loader/>

  return (
    <Container>
      <FiltersSection>
        <Image1 src='https://cdn.shopify.com/s/files/1/0647/9953/9386/files/mike-nguyen-t-4xEHYhr2g-unsplash.jpg?v=1728110833'/>
        <Image1 src='https://cdn.shopify.com/s/files/1/0647/9953/9386/files/patrick-perkins-iRiVzALa4pI-unsplash.jpg?v=1728111715'/>
        <Image1 src='https://cdn.shopify.com/s/files/1/0647/9953/9386/files/ritchie-valens-6fxiPO6bPpM-unsplash_1_4ead1632-1f17-44e7-968e-721cb52ba9ce.jpg?v=1726124568'/>
      </FiltersSection>
      {/* <FiltersSection>
        <h2>Filters</h2>
        <FilterItem>
          <label>No. of Bedrooms</label>
          <ButtonGroup>
            <Button active={filters.bedrooms === "1 BHK"} onClick={() => handleFilterChange('bedrooms', '1 BHK')}>1 BHK</Button>
            <Button active={filters.bedrooms === "2 BHK"} onClick={() => handleFilterChange('bedrooms', '2 BHK')}>2 BHK</Button>
          </ButtonGroup>
        </FilterItem>
        <FilterItem>
          <label>Location</label> */}
          {/* <CheckboxGroup>
            <Checkbox>
              <input
                type="checkbox"
                checked={filters.location.includes('OMR')}
                onChange={() => handleFilterChange('location', 'OMR')}
              />
              OMR
            </Checkbox>
            <Checkbox>
              <input
                type="checkbox"
                checked={filters.location.includes('Avadi')}
                onChange={() => handleFilterChange('location', 'Avadi')}
              />
              Avadi
            </Checkbox>
          </CheckboxGroup> */}
        {/* </FilterItem>
        <ApplyButton onClick={applyFilters}>Apply</ApplyButton>
      </FiltersSection> */}

      <ListingSection>
        <h2>{propertyName} in {city}</h2>

        <FilterItem>
          <Dropdown
            value={types}
            onChange={(e) => setTypes(e.target.value)}
          >
            <option value="all">All</option>
            <option value="sell">Buy</option>
            <option value="rent">Rent</option>
            <option value="lease">Lease</option>
          </Dropdown>
        </FilterItem>
        <Grid>
          {filteredList.length > 0 ? (
            filteredList.map((item, index) => (
              <Card key={index}>
                <Image
                  src={item.files && item.files[0] ? item.files[0] : 'https://via.placeholder.com/150'}
                  alt={item.propertyName}
                />
                {/* <Description><strong>Types: </strong>{item.types}</Description> */}
                <Description><strong>Property Name: </strong>{item.propertyName}</Description>
                <Description><strong>Property Type: </strong>{item.propertyType}</Description>
                <Description><strong>No. of Sq.Ft: </strong>{item.numberOfSqFeet}</Description>
                {/* <Description><strong>No. of Rooms: </strong>{item.numberOfRooms}</Description> */}
                <Description><strong>City: </strong>{item.city}</Description>
                <ViewButton onClick={() => handleViewDetails(item._id)}>View Details</ViewButton>
              </Card>
            ))
          ) : (
            <NoPropertyMessage>No properties found for {propertyName} in {city}</NoPropertyMessage>
          )}
        </Grid>
      </ListingSection>
    </Container>
  );
};

export default SearchRent;
