import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CountUp from "react-countup";
// Styled Components for PropertiesCount
const Container = styled.div`
  background: linear-gradient(135deg, #F5D0C5, #F7E4DB, #B6E2E9, #D2C4FC
); /* Same pastel gradient */
  padding: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.light};
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;
const Heading = styled.h1`
  color: ${({ theme }) => theme.colors.dark}; /* Applying theme light color */
`;
const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
const Card = styled.div`
  background: linear-gradient(135deg, #2E3A59, #3B5066, #434F81, #7C5DB2
); /* Same dark gradient */
  padding: 1.5rem;
  border-radius: 10px;
  width: 150px;
  margin: 1rem;
  text-align: center;
  @media (max-width: 768px) {
    width: 120px;
    padding: 1rem;
  }
  @media (max-width: 480px) {
    width: 100%;
    margin: 0.5rem 0;
  }
`;
// Helper function to format the property count
const formatCount = (count) => {
  if (count < 5) {
    return count; // Display exact count if less than 5
  } else if (count < 10) {
    return '5+';  // Display 5+ if count is between 5 and 9
  } else if (count < 15) {
    return '10+'; // Display 10+ if count is between 10 and 14
  } else if (count < 20) {
    return '15+'; // Display 15+ if count is between 15 and 19
  } else {
    return `${Math.floor(count / 5) * 5}+`; // For higher counts, display ranges in multiples of 5
  }
};
const WhatWeHave = () => {
  const [counts, setCounts] = useState({});
  const [isInView, setIsInView] = useState(false);
  const containerRef = useRef(null);
  // Fetch property counts from the API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/properties-count`)
      .then((response) => response.json())
      .then((data) => setCounts(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  // Intersection Observer to detect when component is in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect(); // Stop observing once it enters the viewport
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the component is visible
    );
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);
  return (
    <Container ref={containerRef}>
      <Heading>What We Have</Heading>
      <CardsWrapper>
        {Object.keys(counts)
          .sort() // Sort keys alphabetically
          .map((property) => (
            <Card key={property}>
              {isInView && ( // Only start animation when in view
                <h2>
                  <CountUp
                    start={0}
                    end={counts[property]} // End value from API
                    duration={5.5} // Animation duration
                    formattingFn={() => formatCount(counts[property])} 
                />
                </h2>
              )}
              <h4>{property}</h4>
            </Card>
          ))}
      </CardsWrapper>
    </Container>
  );
};
export default WhatWeHave;











