import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; 

const SignupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('https://cdn.shopify.com/s/files/1/0851/4607/5431/files/Untitled_design_20.png?v=1725961182'); /* Same background image as Signin */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const SignupBox = styled.div`
  background-color: rgba(255, 255, 255, 0.1); /* Transparent background */
  backdrop-filter: blur(10px); /* Creates a glassy effect */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Softer shadow */
  width: 350px; /* Adjusted width to match Signin.js */
  text-align: center;
  border: 2px solid rgba(255, 255, 255, 0.5); /* Adds a mirror-like border */
  color: white; /* For better visibility of text on glass */
  position:relative;
  bottom:30px;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 30px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  padding: 12px; /* Match Signin.js padding */
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  box-sizing: border-box;
  width: 100%; /* Ensure input fields take full width like in Signin.js */
  background-color: ${({ theme }) => theme.colors.extralight};
`;

const Select = styled.select`
padding: 12px; /* Match Signin.js padding */
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  box-sizing: border-box;
  width: 100%; /* Ensure input fields take full width like in Signin.js */
  background-color: ${({ theme }) => theme.colors.extralight};

`

const EyeIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: ${({ theme }) => theme.colors.medium};
`;

const Button = styled.button`
  padding: 12px; /* Same padding as Signin.js */
  background-color: ${({ theme }) => theme.colors.medium};
  color: ${({ theme }) => theme.colors.light};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  width: 100%; /* Match width to the input fields like in Signin.js */
  &:hover {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;

const Message = styled.div`
  color: ${({ isSuccess }) => (isSuccess ? 'green' : 'red')}; /* Green for success, red for error */
  margin-bottom: 15px;
  font-weight: bold;
`;

const Error = styled.div`
  color: red;
  margin-bottom: 10px;
  font-size: 14px;
`;

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    city: '', // Add city field to state
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [districts, setDistricts] = useState([]); // State for storing districts
  const navigate = useNavigate();

  // Dummy data for Tamil Nadu districts
  const tamilNaduDistricts = [
    'Ariyalur', 'Chennai', 'Coimbatore', 'Cuddalore', 'Dharmapuri',
    'Dindigul', 'Erode', 'Kanchipuram', 'Kanyakumari', 'Karur',
    'Krishnagiri', 'Madurai', 'Nagapattinam', 'Namakkal', 'Perambalur',
    'Pudukkottai', 'Ramanathapuram', 'Salem', 'Sivaganga', 'Thanjavur',
    'Theni', 'Tiruchirappalli', 'Tirunelveli', 'Tirupattur', 'Tiruvallur','Tiruppur',
    'Tiruvannamalai', 'Vellore', 'Virudhunagar', 'Kodaikanal', 'Kanyakumari',
];

  useEffect(() => {
    // Load the districts when the component mounts
    setDistricts(tamilNaduDistricts);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phoneNumber') {
      if (/^\d{0,10}$/.test(value)) {
        setFormData({ ...formData, [name]: value });
        setError('');
      } else {
        setError('Phone number must be numeric and 10 digits long.');
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    // Validate phone number length
    if (formData.phoneNumber.length !== 10) {
      setError('Phone number must be exactly 10 digits.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      const result = await response.json();

      if (response.ok) {
        setSuccessMessage('Signup successful!');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          phoneNumber: '',
          city: '', // Reset city field
        });
        navigate('/signin');
      } else {
        if (result.message && result.message.includes('email')) {
          setError('Email is already taken. Please use a different email.');
        } else {
          setError(result.message || 'Signup failed.');
        }
      }
    } catch (error) {
      setError('Signup failed. Please try again later.');
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <SignupContainer>
      <SignupBox>
        <Title>Sign Up</Title>
        {error && <Message>{error}</Message>}
        {successMessage && <Message isSuccess>{successMessage}</Message>}
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First Name"
            required
          />
          <Input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last Name"
            required
          />
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
          <InputWrapper>
            <Input
              type={passwordVisible ? 'text' : 'password'}
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
              required
            />
            <EyeIcon onClick={togglePasswordVisibility}>
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </EyeIcon>
          </InputWrapper>
          <Input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Phone Number"
            required
            maxLength="10"
            pattern="\d{10}"
            title="Phone number must be 10 digits"
          />
          <Select 
            name="city" 
            value={formData.city} 
            onChange={handleChange} 
            required
          >
            <option value="" disabled>Select City</option>
            {districts.map((district) => (
              <option key={district} value={district}>
                {district}
              </option>
            ))}
          </Select  >
          <Button type="submit">Sign Up</Button>
        </Form>
      </SignupBox>
    </SignupContainer>
  );
};

export default Signup;